import { useAppState } from "../state";

export function useGetTemplateValue() {
  const [{ configuration: configState }] = useAppState();

  function getTemplateValue(providedDistro, providedVersion, providedControlPanel) {
    const distro = providedDistro ?? configState.operatingSystemDistro;
    const version = providedVersion ?? configState.operatingSystemVersion;
    const controlPanel = providedControlPanel ?? configState.controlPanel;

    if (!distro || !version || !controlPanel) {
      return {
        distro,
        version,
        controlPanel: '',
        template: '',
      }
    }

    const { availableDistros } = configState;

    if (!availableDistros || !availableDistros[distro]) {
      return {
        distro,
        version,
        controlPanel: '',
        template: '',
      }
    }

    // First, find matching distro and version
    const distroData = availableDistros[distro].find(
      (distro) => distro.version === version,
    );
    const controlPanelCount = distroData?.control_panels.length;

    // If only one control panel, return template value
    if (controlPanelCount === 1) {
      const nextControlPanel = distroData?.control_panels[0];

      return {
        distro,
        version,
        controlPanel: nextControlPanel.name || "NoCP",
        template: nextControlPanel.template || "",
      }
    }

    // Find the control panle matching the current state
    const distroControlPanel = distroData?.control_panels.find(
      (cp) => cp.name === controlPanel,
    );

    if (distroControlPanel) {
      return {
        distro,
        version,
        controlPanel: distroControlPanel?.name || "NoCP",
        template: distroControlPanel?.template || "",
      }
    }

    // Find the default control panel
    const defaultControlPanel = distroData?.control_panels.find((cp) => cp.default);

    if (defaultControlPanel) {
      return {
        distro,
        version,
        controlPanel: defaultControlPanel?.name || "NoCP",
        template: defaultControlPanel?.template || "",
      }
    }

    // Find the control panel with the lowest display order
    const priorityControlPanel = distroData?.control_panels.reduce((min, cp) => {
      return cp.display_order < min.display_order ? cp: min;
    });

    if (priorityControlPanel) {
      return {
        distro,
        version,
        controlPanel: priorityControlPanel?.name || "NoCP",
        template: priorityControlPanel?.template || "",
      }
    }

    return {
      distro,
      version,
      controlPanel: '',
      template: '',
    }
  }

  return {
    getTemplateValue,
  };
}
