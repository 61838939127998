import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppState } from "../../../state";
import configActions from "../../../state/configuration/actions";
import summaryActions from "../../../state/summary/actions";

import { CardButtonBase } from "../common/card/CardButtonBase";
import { Chip } from "../common/chip";
import { SkeletonCard } from "../common/skeleton-card";

import { getHardwareTabByServerType } from "../../../utils/helperFunctions";

import { DEFAULT_SUBSCRIPTION, PRODUCT_TYPES, SERVER_TYPE_CLOUD_METAL, SERVER_TYPE_BARE_METAL, SERVER_TYPE_GPU } from "../constants";

export function SectionServerType() {
  const [{ configuration: configState }, dispatch] = useAppState();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  function handleServerTypeSelection(nextServerType) {
    const { serverType } = configState;
    const tab = getHardwareTabByServerType(nextServerType);

    if (nextServerType === serverType) {
      return;
    }

    // Reset these params on Server Type selection
    searchParams.delete('config');
    searchParams.delete('cycle');
    searchParams.delete('htab');
    searchParams.delete('itab');
    searchParams.delete('os');

    setSearchParams(searchParams);

    dispatch(configActions.setSubscriptionCycle(DEFAULT_SUBSCRIPTION));
    dispatch(configActions.setHardwareTab(tab));
    dispatch(configActions.setServerType(nextServerType));
    dispatch(configActions.setServerQty(1));

    const isBareMetal = [SERVER_TYPE_BARE_METAL, SERVER_TYPE_GPU].includes(nextServerType);

    if (isBareMetal) {
      const defaultImageTab = SERVER_TYPE_GPU === nextServerType ? 'app' : 'os';
      // Pre-select OS tab
      dispatch(configActions.setOperatingSystemTab(defaultImageTab));
      // Bare metal server types are all self-managed
      dispatch(configActions.setManagementLevel('Self-Managed'));
    }

    navigate({
      pathname: PRODUCT_TYPES[nextServerType].path,
      search: searchParams.toString(),
    });
  }

  return (
    <div className="mb-16 3xl:mb-28">
      <h3 className="text-xl font-normal mt-0 mb-6">Server Type</h3>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 gap-2 sm:gap-[10px]">
        {configState.isLoading
          ? Array(4)
            .fill()
            .map((_, index) => (
              <SkeletonCard
                key={index}
                className="h-[178px] lg:h-[226px] overflow-hidden"
              >
                <span className="block min-w-[302px]"></span>
              </SkeletonCard>
            ))
          : null}
        {!configState.isLoading &&
          Object.keys(PRODUCT_TYPES).map((type) => {
            const product = PRODUCT_TYPES[type];

            return (
              <CardButtonBase
                key={type}
                className="!pb-6 !items-start"
                isSelected={type === configState.serverType}
                onClick={() => handleServerTypeSelection(type)}
              >
                <div className="flex flex-row-reverse sm:flex-col gap-4 sm:gap-0 items-start w-full h-full">
                  <div className="flex justify-end grow-1 shrink-0 basis-16 sm:basis-0 min-h-[30px]">
                    {product.logo}
                  </div>
                  <div className="flex flex-col flex-grow items-start">
                    <h4 className="text-xl font-normal mt-0 sm:mt-4 mb-2">
                      {product.name}
                    </h4>
                    <p className="flex-grow text-base text-lw-text-disabled mb-4">
                      {product.description}
                    </p>
                    <Chip>{product.cost}</Chip>
                  </div>
                </div>
              </CardButtonBase>
            );
          })}
      </div>
    </div>
  );
}
