import {
  SERVER_TYPE_BARE_METAL,
  SERVER_TYPE_CLOUD_METAL,
  SERVER_TYPE_GPU,
} from "../components/configuration/constants";

/**
 * Returns the URL of the template asset image based on the provided name.
 *
 * @param {string} name - The name of the template asset.
 * @returns {URL|string} - The URL of the template asset image or an empty string if not found.
 */
export function getTemplateAsset(name = "") {
  const parts = name.split(" ");
  const token = parts?.[0] ? parts[0].toLowerCase() : "";

  switch (token) {
    case "alma":
    case "almalinux":
      return new URL(
        "../images/server-os/alma-linux.png?width=40",
        import.meta.url,
      );
    case "alpine":
      return new URL(
        "../images/server-os/alpine.png?width=40",
        import.meta.url,
      );
    case "arch":
      return new URL("../images/server-os/arch.png?width=40", import.meta.url);
    case "centos":
      return new URL(
        "../images/server-os/cent-os.png?width=40",
        import.meta.url,
      );
    case "cloudlinux":
      return new URL(
        "../images/server-os/cloud-linux.png?width=40",
        import.meta.url,
      );
    case "debian":
      return new URL(
        "../images/server-os/debian.png?width=40",
        import.meta.url,
      );
    case "fedora":
      return new URL(
        "../images/server-os/fedora.png?width=40",
        import.meta.url,
      );
    case "gpu":
      return new URL("../images/server-os/gpu.png?width=40", import.meta.url);
    case "rocky":
    case "rockylinux":
    case "rocky linux":
      return new URL(
        "../images/server-os/rocky-linux.png?width=40",
        import.meta.url,
      );
    case "ubuntu":
      return new URL(
        "../images/server-os/ubuntu.png?width=40",
        import.meta.url,
      );
    case "windows":
      return new URL(
        "../images/server-os/windows-icon.png?width=40",
        import.meta.url,
      );
    case "wordpress":
    case "wordpress optimized":
      return new URL(
        "../images/server-os/wordpress.png?width=40",
        import.meta.url,
      );
    default:
      return "";
  }
}

/**
 * Returns the hardware tab name based on the server type.
 *
 * @param {string} serverType - The type of the server.
 * @returns {string} - The name of the hardware tab.
 */
export function getHardwareTabByServerType(serverType) {
  switch (serverType) {
    case SERVER_TYPE_GPU:
      return "bare-metal-gpu";
    case SERVER_TYPE_BARE_METAL:
    case SERVER_TYPE_CLOUD_METAL:
      return "bare-metal";
    default:
      return "gp";
  }
}
