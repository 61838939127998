import classnames from "classnames";
import find from "lodash/find";
import inRange from "lodash/inRange";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { useAppState } from "../../../state";
import configActions from "../../../state/configuration/actions";
import summaryActions from "../../../state/summary/actions";

import { configurationHelper } from "../../../utils/configurationHelper";
import { removeDecimalIfWhole } from "../../../utils/removeDecimalIfWhole";

import { useProductDetails } from "../../../hooks/useProductDetails";

import { Chip } from "../common/chip";
import { Select } from "../common/select";
import { SkeletonCard } from "../common/skeleton-card";
import { Picker, PickerItem } from "../picker";
import { QuantityControl } from "../quantity-control";

import { BLOCK_STORAGE_PRESETS} from "../constants";

export function SectionBlockStorage() {
  const inputRef = useRef();
  const [{ configuration: configState }, dispatch] = useAppState();
  const [hasSelected, setHasSelected] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [monthlyCost, setMonthlyCost] = useState(0);
  const productData = useProductDetails();
  const { data } = productData[configState.productType] || {};
  const showSkeleton = configState.isLoading || ["api-fetch", "management"].includes(configState.isError);

  const blockStorage = useMemo(() => {
    const { getDataByRegion } = configurationHelper(data);

    if (!data) {
      return { max: 0, min: 0, cost: 0 };
    }

    const presetValues = BLOCK_STORAGE_PRESETS.map(preset => preset.value);
    const { block_storage } = getDataByRegion(configState.serverLocation);
    const perGb = find(block_storage, { name: "per_gb" })?.per_gb_price;

    if (!perGb) {
      return { max: 0, min: 0, cost: 0 };
    }

    return {
      max: Math.max(...presetValues),
      min: Math.min(...presetValues),
      cost: getMonthlyCost(perGb),
    };

    // Note: productData is excluded from the dependency array to prevent infinite loop
  }, [data, configState.productType, configState.serverLocation, configurationHelper]);

  function getMonthlyCost(priceArray = []) {
    const priceObject = find(priceArray, { unit: "month" });

    return priceObject?.amount ? Number(priceObject.amount) : 0.1;
  }

  useEffect(() => {
    dispatch(
      summaryActions.setBlockStorage({
        value: configState.blockStorage === 0 ? "None" : `${configState.blockStorage} GB`,
        cost: monthlyCost,
      }),
    );
  }, [monthlyCost, configState.blockStorage]);

  useEffect(() => {
    // Reset block storage state if no cost
    if (!blockStorage.cost) {
      dispatch(configActions.setBlockStorage(0));
      dispatch(
        summaryActions.setBlockStorage({
          value: "None",
          cost: 0,
        }),
      );
      return;
    }

    const isValuePresent = BLOCK_STORAGE_PRESETS.some((preset) => preset.value === configState.blockStorage);

    setIsCustom(!isValuePresent);
    setMonthlyCost(
      Math.round(configState.blockStorage * blockStorage.cost * 100) / 100,
    );
  }, [blockStorage, configState.blockStorage]);

  function handleMobileSelection({ target: { value } }) {
    if (value !== "custom") {
      dispatch(configActions.setBlockStorage(Number(value)));
    }
  }

  function getFooterDisplay() {
    return (
      <div className="flex justify-between items-center">
        <QuantityControl
          size="xs"
          min={blockStorage.min}
          max={blockStorage.max}
          wrapperElemClasses={hasSelected ? "bg-lw-off-white" : null}
          inputWrapperElemClasses="!gap-0"
          inputRef={inputRef}
          inputSuffix={<span className="text-lw-text-disabled">GB</span>}
          value={configState.blockStorage}
          onChange={(value) => {
            setHasSelected(true);
            const intValue = Number.isInteger(value) ? value : Math.ceil(value);
            dispatch(configActions.setBlockStorage(intValue));
          }}
        />
        <Chip>{`+$${removeDecimalIfWhole(monthlyCost)} Monthly`}</Chip>
      </div>
    );
  }

  const pickerClasses = classnames("hidden", "md:block", {
    "!border-lw-disabled": hasSelected,
    "!outline": hasSelected,
    "!outline-1": hasSelected,
    "!outline-lw-ui-border-active": hasSelected,
    "!bg-white": hasSelected,
  });

  if (!blockStorage.cost) {
    return null;
  }

  return (
    <div>
      <h3 className="text-xl font-normal mt-0 mb-2">
        Cloud Block Storage{" "}
        <span className="font-normal text-sm text-lw-text-disabled">
          (Optional)
        </span>
      </h3>
      <p className="mb-6">
        Physical components powering servers for data processing and storage.
      </p>

      <div className="md:hidden">
        {showSkeleton ? (
          <>
            <SkeletonCard className="h-[50px] mb-6" />
            <SkeletonCard className="h-[66px]" />
          </>
        ) : null}
        {!configState.isLoading && !configState.isError ? (
          <>
            <Select
              controlElemClass="mb-6"
              onChange={handleMobileSelection}
              value={isCustom ? "custom" : configState.blockStorage}
            >
              {BLOCK_STORAGE_PRESETS.map((preset, index) => (
                <option key={index} value={preset.value}>
                  {preset.label}
                </option>
              ))}
              <option value="custom">Custom</option>
            </Select>
            <QuantityControl
              wrapperElemClasses="!bg-white"
              inputWrapperElemClasses="!gap-0"
              value={configState.blockStorage}
              inputRef={inputRef}
              size="lg"
              min={blockStorage.min}
              max={blockStorage.max}
              inputSuffix={<span className="text-lw-text-disabled">GB</span>}
              onChange={(value) => {
                setHasSelected(true);
                dispatch(configActions.setBlockStorage(value));
              }}
            >
              <>
                <span className="relative text-2xl">
                  <span aria-hidden="true" className="px-2">
                    {configState.blockStorage}
                  </span>
                  <input
                    className={classnames(
                      "absolute",
                      "left-0",
                      "w-full",
                      "h-full",
                      "text-center",
                      "[appearance:textfield]",
                      "[&::-webkit-outer-spin-button]:appearance-none",
                      "[&::-webkit-inner-spin-button]:appearance-none",
                      "outline-none",
                      "bg-transparent",
                    )}
                    type="number"
                    value={configState.blockStorage}
                    onChange={(event) => {
                      const value = Number.isInteger(event.target.value)
                        ? event.target.value
                        : Math.ceil(event.target.value);
                      if (inRange(value, blockStorage.min, blockStorage.max + 1)) {
                        dispatch(configActions.setBlockStorage(value));
                      }
                    }}
                  />
                </span>
                <span className="text-lw-text-disabled">GB</span>
                <Chip className="ml-6">{`+$${removeDecimalIfWhole(monthlyCost)} Monthly`}</Chip>
              </>
            </QuantityControl>
          </>
        ) : null}
      </div>

      {showSkeleton ? (
        <SkeletonCard className="h-[149px] hidden md:block" />
      ) : (
        <Picker footer={getFooterDisplay()} className={pickerClasses}>
          {BLOCK_STORAGE_PRESETS.map((preset) => (
            <PickerItem
              key={preset.value}
              isDefault={0 === preset.value && !hasSelected}
              active={configState.blockStorage === preset.value}
              onClick={() => {
                setHasSelected(true);
                dispatch(configActions.setBlockStorage(preset.value));
              }}
            >
              <span className="text-xs">{preset.label}</span>
            </PickerItem>
          ))}
          <PickerItem
            active={isCustom}
            onClick={() => {
              setHasSelected(true);
              inputRef.current.focus();
            }}
          >
            <span className="text-xs">Custom</span>
          </PickerItem>
        </Picker>
      )}
    </div>
  );
}
