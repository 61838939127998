import React, { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { useAppState } from "../../../state";
import configActions from "../../../state/configuration/actions";

import { configurationHelper } from "../../../utils/configurationHelper";

import { useProductDetails } from "../../../hooks/useProductDetails";

import { Chip } from "../common/chip";
import { SkeletonCard } from "../common/skeleton-card";
import { SubscriptionCard } from "../subscription-card";

import { CYCLE_MAP } from "../constants";

export function SectionSubscription() {
  const [{ configuration: configState }, dispatch] = useAppState();
  const productData = useProductDetails();
  const [searchParams] = useSearchParams();
  const { data } = productData[configState.productType] || {};
  const { sortByKey } = configurationHelper(data);
  const showSkeleton =
    configState.isLoading || configState.isError === "api-fetch";

  const cycles = useMemo(() => {
    const { cycles } = data || {};

    if (!cycles) {
      return [];
    }

    return sortByKey(cycles, "months");
  }, [data]);

  const handleSubscriptionCycle = useCallback(
    (cycle) => {
      const subscriptionDiscount = {
        cycle: cycle.cycle,
        discount: Number(cycle.discount_percent),
        months: cycle.months,
      };
      dispatch(configActions.setSubscriptionCycle(subscriptionDiscount));
    },
    [dispatch],
  );

  useEffect(() => {
    const defaultCycle = searchParams.get("cycle");

    if (defaultCycle && cycles.length) {
      const cycle = cycles.find((c) => c.cycle === defaultCycle);

      if (cycle) {
        handleSubscriptionCycle(cycle);
      }
    }
  }, [searchParams, cycles, handleSubscriptionCycle]);

  function getDiscountedChip(cycleObject) {
    const { discounts, cycle } = cycleObject || {};

    // Show discount chip only for non-monthly cycles
    if (cycle === 'monthly' || !discounts || !discounts.length) {
      return null;
    }

    const maxCycleDiscount = Math.max(...discounts.map(discount => Number(discount.amount)));
    const hasMultipleDiscounts = discounts.length > 1;

    return maxCycleDiscount > 0 ? (
      <Chip variant="secondary">
        {hasMultipleDiscounts ? `Save Up To ${maxCycleDiscount}%` : `Save ${maxCycleDiscount}%`}
      </Chip>
    ) : null;
  }

  if (!configState.hasSubscriptionCycle) {
    return null;
  }

  return (
    <div className="mb-16 3xl:mb-28">
      <h3 className="text-xl font-normal mt-0 mb-2">
        Select Your Subscription
      </h3>
      <p className="mb-6">Select your preferred billing cycle and payment frequency.</p>

      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-2 sm:gap-4">
        {showSkeleton
          ? Array(3)
            .fill()
            .map((_, index) => (
              <SkeletonCard key={index} className="h-[178px] lg:h-[155px]" />
            ))
          : cycles.map((cycle) => (
            <SubscriptionCard
              key={cycle.cycle}
              cycle={cycle}
              title={CYCLE_MAP.get(cycle.cycle).short}
              chip={getDiscountedChip(cycle)}
              isSelected={cycle.cycle === configState.subscriptionCycle.cycle}
              onClick={() => handleSubscriptionCycle(cycle)}
            />
          ))}
      </div>
    </div>
  );
}
