import classnames from "classnames";
import { find, sortBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";

import { useAppState } from "../../../state";
import configActions from "../../../state/configuration/actions";
import summaryActions from "../../../state/summary/actions";

import { configurationHelper } from "../../../utils/configurationHelper";
import { removeDecimalIfWhole } from "../../../utils/removeDecimalIfWhole";

import { useProductDetails } from "../../../hooks/useProductDetails";

import InfoIcon from "jsx:../../../images/icon-info.inline.svg";
import { ButtonPill } from "../common/button/ButtonPill";
import { Info } from "../common/info";
import { Select } from "../common/select";
import { SkeletonCard } from "../common/skeleton-card";
import { HardwareCard } from "../hardware-card";
import { HardwareToolTip } from "../hardware-tooltip/hardware-tooltip";

import {
  DEFAULT_DISCOUNT,
  HARDWARE_CATEGORY_DESCRIPTIONS,
  HARDWARE_CATEGORY_ORDER,
  PRODUCT_TYPES,
  SERVER_TYPE_BARE_METAL,
  SERVER_TYPE_CLOUD_METAL,
  SERVER_TYPE_CLOUD_VPS,
  SERVER_TYPE_GPU,
} from "../constants";

const images = {
  windows: new URL(
    "../../../images/server-os/windows-icon.png?height=16",
    import.meta.url,
  ),
};

import { IWORX_MIN_MEMORY_GB } from "./SectionControlPanel";

/**
 * SectionHardware component renders the hardware selection section.
 * It handles the display and selection of various hardware options based on the server type and other configurations.
 *
 * @returns {JSX.Element} The rendered SectionHardware component.
 */
export function SectionHardware() {
  const [showCategoryInfo, setShowCategoryInfo] = useState(false);
  const [showControlPanelNotice, setShowControlPanelNotice] = useState(false);
  const [showWindowsFeeNotice, setShowWindowsFeeNotice] = useState(false);
  const [activeConfigId, setActiveConfigId] = useState(null);
  const [configs, setConfigs] = useState([]);
  const [{ configuration: configState }, dispatch] = useAppState();
  const [searchParams] = useSearchParams();
  const productData = useProductDetails();
  const location = useLocation();
  const { data } = productData[configState.productType] || {};
  const { getHardwareOptionsByRegion, sortByKey } = configurationHelper(data);
  const serverType = location.pathname.replace(/\//g, "") || SERVER_TYPE_CLOUD_VPS;
  const isMetalServerType = [SERVER_TYPE_CLOUD_METAL, SERVER_TYPE_BARE_METAL, SERVER_TYPE_GPU].includes(configState.serverType);
  const showSkeleton =
    configState.isLoading || ["api-fetch", "management"].includes(configState.isError);

  /**
   * Memoized function to get hardware data based on the current configuration state.
   *
   * @returns {Array} The hardware data.
   */
  const hardwareData = useMemo(() => {
    if (!data) {
      return [];
    }

    const tab = getInitialTab();
    const hardwareByCategory = [];
    const configs = sortHardware(
      getHardwareOptionsByRegion(configState.serverLocation),
    );
    const newHardware =
      data?.hardware &&
        data.hardware.map((level) => ({
          ...level,
          configs: prepareAvailableConfigs(level.configs, configs),
        }));

    // This matches Cloud Metal, Bare Metal and Bare Metal GPU
    if (["bare-metal", "bare-metal-gpu"].includes(tab)) {
      return newHardware;
    }

    HARDWARE_CATEGORY_ORDER.forEach((category) => {
      const match = find(newHardware, { category: category });
      if (match) {
        hardwareByCategory.push(match);
      }
    });

    return hardwareByCategory;
  }, [
    data,
    configState.subscriptionCycle,
    configState.productCode,
    configState.managementLevel,
    configState.serverLocation,
    configState.serverType,
  ]);

  useEffect(() => {
    const htab = getInitialTab();

    if (htab) {
      dispatch(configActions.setHardwareTab(htab));
    }
  }, [searchParams, dispatch, configActions]);

  useEffect(() => {
    const tab = getInitialTab();
    setDefaultHardwareChoice(tab);
  }, [hardwareData]);

  // If InterWorx is selected but the current config has
  // insufficient RAM to support it, then we automatically
  // select the smallest config that is able to support
  // InterWorx and display a message to the user.
  useEffect(() => {
    const configs = getCurrentHardwareConfigs(configState.hardwareTab);
    const activeConfig = find(configs, { id: configState.hardwareOption });
    const configsWithMinMemory = configs.filter((val) => Number(val.memory) >= IWORX_MIN_MEMORY_GB);

    if (!activeConfig) return;
    if (configsWithMinMemory.length === 0) return;
    if (configState.controlPanel !== "Interworx") return;
    if (Number(activeConfig.memory) >= IWORX_MIN_MEMORY_GB) return;

    const iworxMinConfig =
      configsWithMinMemory
        .reduce((acc, val) =>
          Number(val.memory) < Number(acc.memory) ? val : acc,
        ) ?? activeConfig;

    updateHardwareSelection(iworxMinConfig, configState.hardwareTab);

    dispatch(configActions.toggleImplicitConfigUpdateNotice(true));

    // CAUTION: Hooks in this component are missing dependencies,
    // but fixing this is not straightforward. We are following the
    // prevailing pattern here for practical reasons, accepting
    // the possibility of subtle bugs until this app is sunset
    // in the near future.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configState.controlPanel, configState.hardwareOption]);

  useEffect(() => {
    setShowWindowsFeeNotice(
      (configState.isError === "" || configState.isError === "api-post") &&
        configState.operatingSystemType === "windows",
    );
    setShowControlPanelNotice(
      configState.serverType === SERVER_TYPE_CLOUD_VPS &&
        configState.controlPanel !== "NoCP" &&
        configState.managementLevel !== "Core-Managed",
    );
    setShowCategoryInfo(
      configState.hardwareTab &&
        configState.hardwareTab !== "bare-metal" &&
        configState.hardwareTab !== "bare-metal-gpu",
    );
  }, [
    configState.hardwareTab,
    configState.serverType,
    configState.managementLevel,
    configState.controlPanel,
    configState.isError,
    configState.operatingSystemType,
  ]);

  /**
   * Determines the initial hardware tab based on search parameters and configuration state.
   *
   * @returns {string} The initial hardware tab.
   */
  function getInitialTab() {
    if (searchParams.get("htab")) {
      return searchParams.get("htab");
    }

    if ([SERVER_TYPE_BARE_METAL, SERVER_TYPE_CLOUD_METAL].includes(serverType)) {
      return "bare-metal";
    }

    if (configState.serverType === SERVER_TYPE_GPU) {
      return "bare-metal-gpu";
    }

    // For Cloud VPS, return the tab value from state, if set.
    return configState.hardwareTab ? configState.hardwareTab : "gp";
  }

  /**
   * Prepares available configurations by matching potential configs with available configs.
   *
   * @param {Array} potentialConfigs - The potential configurations.
   * @param {Array} availableConfigs - The available configurations.
   * @returns {Array} The prepared configurations.
   */
  function prepareAvailableConfigs(potentialConfigs, availableConfigs) {
    const matchingConfigs = [];
    const { cycles } = data || {};

    potentialConfigs.forEach((config) => {
      const match = find(availableConfigs, { id: config.id });

      if (match) {
        const discount = getAvailableDiscounts(match);
        
        matchingConfigs.push(
          Object.assign(config, match, {
            monthlyPrice: getMonthlyCost(match.price),
            discount: discount || {},
          }),
        );
      }
    });

    return sortByKey(matchingConfigs, "display_order");
  }

  function getAvailableDiscounts(config) {
    // Current subscription cycle
    const cycle = configState.subscriptionCycle?.cycle;
    // Available cycles from the product data
    const availableCycles = data?.cycles || [];

    // Find the matching cycle from the available cycles
    const matchingCycle = availableCycles.find(({ cycle: availableCycle }) => availableCycle === cycle);
    if (!matchingCycle) {
      return null;
    }

    // Find the config-specific discount
    const matchingDiscount = matchingCycle?.discounts?.find(({ package_product_option_id }) => package_product_option_id === config.package_product_option_id);

    if (matchingDiscount && Number(matchingDiscount.amount) > 0) {
      return {
        cycle,
        type: 'option',
        discount: Number(matchingDiscount.amount),
        repetitions: matchingDiscount.month_repetitions,
      };
    } else if (matchingCycle.discount_percent > 0) {
      return {
        cycle,
        type: 'cycle',
        discount: Number(matchingCycle.discount_percent),
        repetitions: matchingCycle.discount_repetitions,
      };
    }

    return null;
  }

  /**
   * Gets the monthly cost from a price array.
   *
   * @param {Array} priceArray - The array of price objects.
   * @returns {number} The monthly cost.
   */
  function getMonthlyCost(priceArray = []) {
    const priceObject = find(priceArray, { unit: "month" });

    return priceObject?.amount ? priceObject.amount : 0;
  }

  /**
   * Gets the display cost for a hardware item.
   *
   * @param {Object} hardware - The hardware item.
   * @returns {Object} An object containing fullCost and discountedCost.
   */
  function getDisplayCost(hardware) {
    const discountType = hardware?.discount?.type;
    // Global discount
    const { discount } = configState.subscriptionCycle;
    // Config-specific discount
    const { discount: configDiscount, repetitions } = hardware?.discount || {};
    const basePrice = getMonthlyCost(hardware.price);
    const availableDiscount = discountType === 'option' ? Number(configDiscount) : discount;

    let discountedContent = "";
    if (discountType) {
      discountedContent = `Save ${availableDiscount}%${repetitions ? ` for ${repetitions} months` : " per month"}`;
    }

    if (!basePrice) {
      return {
        fullCost: "",
        discountedCost: "",
        discountedContent: "",
      };
    }

    const windowsLicenseObject = getWindowsLicenseObject(hardware);
    const windowsLicenseFee = windowsLicenseObject?.price
      ? getMonthlyCost(windowsLicenseObject.price)
      : 0;

    if (configState.operatingSystemType === "windows" && windowsLicenseFee) {
      const licensedCost = Number(basePrice) + Number(windowsLicenseFee);

      return {
        fullCost: removeDecimalIfWhole(licensedCost),
        discountedCost: removeDecimalIfWhole(
          licensedCost - licensedCost * parseFloat(Number(availableDiscount)) * 0.01,
        ),
        discountedContent,
      };
    }

    return {
      fullCost: removeDecimalIfWhole(basePrice),
      discountedCost: removeDecimalIfWhole(
        basePrice - basePrice * parseFloat(Number(availableDiscount)) * 0.01,
      ),
      discountedContent,
    };
  }

  /**
   * Gets the CPU model value with appropriate prefix based on the count.
   *
   * @param {string} model - The CPU model.
   * @param {number} count - The count of CPUs.
   * @returns {string} The formatted CPU model value.
   */
  function getCpuModelValue(model, count = 1) {
    if (!model) {
      return "";
    }

    const DUAL = "Dual";
    const QUAD = "Quad";

    if (count === 2 && !model.trim().startsWith(DUAL)) {
      return `${DUAL} ${model}`;
    }

    if (count === 4 && !model.trim().startsWith(QUAD)) {
      return `${QUAD} ${model}`;
    }

    return model;
  }

  /**
   * Gets the network speed value in a readable format.
   *
   * @param {number|string} capacity - The network speed capacity.
   * @returns {string} The formatted network speed value.
   */
  function getNetworkSpeedValue(capacity) {
    if (!capacity) {
      return "";
    }

    if (isNaN(Number(capacity))) {
      return capacity;
    }

    if (Number(capacity) >= 1000) {
      const value = parseFloat(capacity / 1000);
      // Float values are set to one place.
      const formattedValue = !Number.isInteger(value)
        ? value.toFixed(1)
        : value.toString();

      return `${formattedValue} GHz`;
    }

    return `${capacity} MHz`;
  }

  /**
   * Gets the disk value with appropriate prefix based on the RAID level.
   *
   * @param {number|string} capacity - The disk capacity.
   * @param {number} raid - The RAID level.
   * @returns {string} The formatted disk value.
   */
  function getDiskValue(capacity, raid) {
    if (!capacity) {
      return "";
    }

    // Use "2x" prefix if raid level equals 1
    const prefix = raid === 1 ? "2x " : "";

    if (Number(capacity) >= 1000) {
      return `${prefix}${parseInt(capacity / 1000).toFixed(1)} TB`;
    }

    return `${prefix}${capacity} GB`;
  }

  /**
   * Gets the bandwidth value in a readable format.
   *
   * @param {number|string} capacity - The bandwidth capacity.
   * @returns {string} The formatted bandwidth value.
   */
  function getBandwidthValue(capacity) {
    if (!capacity) {
      return "";
    }

    if (isNaN(Number(capacity))) {
      return capacity;
    }

    if (Number(capacity) >= 1000) {
      const value = parseFloat(capacity / 1000);
      // Float values are set to one place.
      const formattedValue = !Number.isInteger(value)
        ? value.toFixed(1)
        : value.toString();

      return `${formattedValue} TB`;
    }

    return `${capacity} GB`;
  }

  /**
   * Sets the default hardware choice based on tab.
   *
   * @param {string} tab - The hardware tab.
   */
  function setDefaultHardwareChoice(tab) {
    // Prefer configId query param, then activeConfigId.
    const configId = Number(searchParams.get("config")) || activeConfigId;
    const initialTab = tab || getInitialTab();
    const configs = getCurrentHardwareConfigs(initialTab);
    // Check if the configId is in the list of available configs.
    const isConfigIdValid = configs.some((config) => config.id === configId);
    // If the configId is not valid or not set, find the default config.
    const getDefaultBy = isConfigIdValid ? { id: configId } : { default: 1 };
    const activeConfig = find(configs, getDefaultBy) || (tab && configs.length ? configs[0] : null);

    setConfigs(configs);

    if (!activeConfig || configs.length === 0) return;

    // If the active config is different from the current active config, update the active config.
    if (activeConfig.id !== activeConfigId) {
      setActiveConfigId(activeConfig.id);
    }

    updateHardwareSelection(activeConfig, tab);
  }

  /**
   * Gets the summary category name based on the hardware tab.
   *
   * @param {string} tab - The hardware tab.
   * @returns {string} The summary category name.
   */
  function getSummaryCategoryName(tab = "gp") {
    const activeCategory = hardwareData.find((o) => o.category === tab);

    if (!isMetalServerType) {
      return activeCategory?.description ? activeCategory.description : "";
    }

    return PRODUCT_TYPES?.[configState.serverType]?.name
      ? PRODUCT_TYPES[configState.serverType].name
      : "";
  }

  /**
   * Gets the current hardware configurations based on the hardware tab.
   *
   * @param {string} tab - The hardware tab.
   * @returns {Array} The current hardware configurations.
   */
  function getCurrentHardwareConfigs(tab = "gp") {
    const hardwareObject = find(hardwareData, (o) => o.category === tab);
    const configs = hardwareObject?.configs ? hardwareObject.configs : [];

    return configs.map((config) => getNormalizedConfig(config));
  }

  /**
   * Normalizes the hardware configuration object.
   *
   * @param {Object} config - The hardware configuration object.
   * @returns {Object} The normalized hardware configuration object.
   */
  function getNormalizedConfig(config) {
    if (!config) {
      return;
    }

    return {
      ...config,
      cpu_model: getCpuModelValue(config.cpu_model, config.cpu_count),
      cpu_speed: getNetworkSpeedValue(config.cpu_speed),
      disk: getDiskValue(config.disk, config.raid_level),
      cores: configState.isBareMetal
        ? `${config.cores} / ${config.vcpu}`
        : config.cores,
      bandwidth: getBandwidthValue(config.bandwidth),
    };
  }

  /**
   * Sorts the hardware configurations.
   *
   * @param {Array} configs - The hardware configurations.
   * @returns {Array} The sorted hardware configurations.
   */
  function sortHardware(configs) {
    return sortBy(configs, ["monthlyPrice", "display_order", "id"]);
  }

  /**
   * Updates the hardware selection and dispatches relevant actions.
   *
   * @param {Object} hardware - The selected hardware object.
   * @param {string} tab - The selected hardware tab.
   */
  function updateHardwareSelection(hardware, tab) {
    const configDiscount = getHardwareDiscount(hardware);

    setActiveConfigId(hardware.id);
    setWindowsAddOns(hardware);

    dispatch(configActions.setHardwareOption(hardware.id));

    const categoryName = getSummaryCategoryName(tab);

    dispatch(
      summaryActions.setHardwareCategory({
        title: "",
        value: categoryName,
        cost: getMonthlyCost(hardware.price),
      }),
    );
    dispatch(summaryActions.setHardwareDetails(hardware));
    dispatch(configActions.setDiscount(configDiscount));
  }

  /**
   * Handles the click event for hardware tab selection.
   *
   * @param {string} tab - The selected hardware tab.
   */
  const handleHardwareTabClick = (tab) => {
    dispatch(configActions.setHardwareTab(tab));
    setDefaultHardwareChoice(tab);
  };

  /**
   * Handles the click event for hardware selection.
   *
   * @param {Object} hardware - The selected hardware object.
   */
  const handleHardwareSelectionClick = (hardware) => {
    const configDiscount = getHardwareDiscount(hardware);

    updateHardwareSelection(hardware, configState.hardwareTab);

    dispatch(configActions.setDiscount(configDiscount));
    dispatch(configActions.toggleImplicitConfigUpdateNotice(false));
  };

  /**
   * Gets the hardware discount object.
   *
   * @param {Object} hardware - The hardware object.
   * @returns {Object} The hardware discount object.
   */
  function getHardwareDiscount(hardware) {
    const { discount } = hardware || {};
    const { cycle, discount: discountPercent, repetitions } = discount || {};

    return (cycle && discountPercent) ? {
      cycle,
      repetitions,
      discount: discountPercent,
    } : DEFAULT_DISCOUNT;

  }

  /**
   * Gets the Windows license object from the hardware options.
   *
   * @param {Object} hardware - The hardware object.
   * @returns {Object} The Windows license object.
   */
  function getWindowsLicenseObject(hardware) {
    const windowsLicense = find(hardware?.included_options, {
      key: "WindowsLicense",
    });

    return windowsLicense?.values?.[0];
  }

  function getMsSqlObject(hardware) {
    const msSQL = find(hardware?.included_options, { key: "MsSQL" });

    return msSQL || {};
  }

  /**
   * Sets the Windows add-ons for the selected hardware.
   *
   * @param {Object} hardware - The selected hardware object.
   */
  function setWindowsAddOns(hardware) {
    const msSqlObject = getMsSqlObject(hardware);
    const windowsLicenseObject = getWindowsLicenseObject(hardware);
    const windowsLicenseName = windowsLicenseObject?.description
      ? windowsLicenseObject.description
      : "";
    const windowsLicenseFee = windowsLicenseObject?.price
      ? getMonthlyCost(windowsLicenseObject.price)
      : 0;

    dispatch(
      summaryActions.setWindowsLicense({
        value: windowsLicenseName,
        cost: windowsLicenseFee,
      }),
    );
    dispatch(
      configActions.setAvailableMsSql(
        msSqlObject?.values ? msSqlObject.values : [],
      ),
    );
  }

  /**
   * Generates a notice for the control panel based on the current hardware configuration.
   * 
   * This function checks the memory of the active hardware configuration and returns a 
   * notice if the memory is less than 4 GB, recommending at least 4 GB for the best 
   * control panel experience. If the control panel is "NoCP", no notice is returned.
   * 
   * @returns {JSX.Element|null} A JSX element containing the notice or null if no notice is needed.
   */
  const getControlPanelNotice = useCallback(() => {
    const { controlPanel, hardwareOption } = configState;
    const tab = getInitialTab();
    const configs = getCurrentHardwareConfigs(tab);
    const activeConfig = find(configs, { id: hardwareOption });

    if (controlPanel === "NoCP") {
      return null;
    }

    if (activeConfig?.memory && Number(activeConfig.memory) < 4) {
      const message = `For the best ${controlPanel} experience, we recommend at least 4 GB of memory.`;
      
      return (
        <ul className="mt-3 text-sm">
          <li
            className={classnames(
              "relative",
              "pl-3",
              "before:absolute",
              "before:left-0",
              "before:pr-2",
              "before:inline-block",
              'before:content-["•"]',
              "before:align-middle",
            )}
          >
            {message}
          </li>
        </ul>
      );
    }
    
    return null;
  }, [configState.hardwareOption, configState.controlPanel, configState.operatingSystemVersionValue]);

  /**
   * Renders the Windows fee notice.
   *
   * @returns {JSX.Element} The rendered Windows fee notice.
   */
  function getWindowsFeeNotice() {
    return (
      <Info icon={
        <img
          className="grow-0 shrink-0"
          src={images.windows}
          alt="Windows Logo"
        />
      }>
        <span>Windows licensing fee is included in your hardware price.</span>
      </Info>
    );
  }

  /**
   * Renders the hardware category information.
   *
   * @returns {JSX.Element|null} The rendered hardware category information or null.
   */
  function getHardwareCategoryInfo() {
    return HARDWARE_CATEGORY_DESCRIPTIONS &&
      HARDWARE_CATEGORY_DESCRIPTIONS.get(configState.hardwareTab) ? (
      <p>{HARDWARE_CATEGORY_DESCRIPTIONS.get(configState.hardwareTab)}</p>
    ) : null;
  }

  /**
   * Determines if a hardware option should be disabled based on the control panel and memory requirements.
   *
   * @param {Object} hardware - The hardware object.
   * @returns {boolean} Whether the hardware option should be disabled.
   */
  const getShouldDisableOption = (hardware) =>
    configState.controlPanel === "Interworx" &&
    Number(hardware.memory) < IWORX_MIN_MEMORY_GB;
  
  return (
    <div className="mb-16 3xl:mb-28">
      <h3 className="text-xl font-normal mt-0 mb-2">Hardware</h3>
      <p className="relative flex mb-6 gap-[10px]">
        Physical components powering servers for data processing and storage.
        {configState.serverType !== SERVER_TYPE_CLOUD_VPS ? (
          <HardwareToolTip />
        ) : null}
      </p>

      {configState.serverType === SERVER_TYPE_CLOUD_VPS ? (
        <div className="mb-6">
          <div className="gap-2 hidden sm:flex">
            {showSkeleton
              ? Array(3)
                  .fill()
                  .map((_, index) => (
                    <SkeletonCard
                      key={index}
                      className="h-[42px] !rounded-full grow"
                    />
                  ))
              : null}
            {!configState.isLoading && !configState.isError && hardwareData
              ? hardwareData.map((filter) => (
                  <ButtonPill
                    key={filter.category}
                    active={filter.category === configState.hardwareTab}
                    onClick={() => handleHardwareTabClick(filter.category)}
                    className="lg:text-sm xl:text-base"
                  >
                    {filter.description}
                  </ButtonPill>
                ))
              : null}
          </div>

          {showSkeleton ? (
            <SkeletonCard className="h-[50px] sm:hidden" />
          ) : (
            <Select
              controlElemClass="sm:hidden"
              onChange={(event) => handleHardwareTabClick(event.target.value)}
              value={configState.hardwareTab}
            >
              {hardwareData
                ? hardwareData.map((filter, index) => (
                    <option key={index} value={filter.category}>
                      {filter.description}
                    </option>
                  ))
                : null}
            </Select>
          )}
        </div>
      ) : null}

      {showControlPanelNotice || showWindowsFeeNotice || showCategoryInfo ? (
        <div className="mb-10 flex flex-col gap-3">
          {showControlPanelNotice || showCategoryInfo ? (
            <Info icon={<InfoIcon />}>
              {getHardwareCategoryInfo()}
              {getControlPanelNotice()}
            </Info>
          ) : null}
          {showWindowsFeeNotice ? getWindowsFeeNotice() : null}
        </div>
      ) : null}

      <div
        id="hardware"
        className={classnames("grid", "gap-2", "sm:gap-4", {
          "grid-cols-2": configState.serverType === SERVER_TYPE_CLOUD_VPS,
          "xl:grid-cols-3": configState.serverType === SERVER_TYPE_CLOUD_VPS,
          "grid-cols-1": isMetalServerType,
          "sm:grid-cols-2": isMetalServerType,
        })}
      >
        {showSkeleton
          ? Array(configState.serverType === SERVER_TYPE_CLOUD_VPS ? 9 : 4)
              .fill()
              .map((_, index) => (
                <SkeletonCard key={index} className="h-[148px]" />
              ))
          : null}
        {!configState.isLoading &&
        (configState.isError === "" || configState.isError === "api-post")
          ? configs.map((hardware) => {
            const hasConfigDiscount = Object.keys(hardware?.discount).length > 0;
            const { fullCost, discountedContent, discountedCost } = getDisplayCost(hardware);

            return (
              <HardwareCard
                key={hardware.id}
                serverType={configState.serverType}
                isSelected={configState.hardwareOption === hardware.id}
                isBareMetal={configState.isBareMetal}
                hasTerms={hasConfigDiscount}
                noticeContent={discountedContent}
                onClick={() => handleHardwareSelectionClick(hardware)}
                data={hardware}
                cost={"delete"}
                fullCost={fullCost}
                discountedCost={discountedCost}
                billingCycle={"Monthly"}
                disabled={getShouldDisableOption(hardware)}
              />
            );
            })
          : null}
      </div>
    </div>
  );
}
